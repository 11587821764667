// About.js

import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

function About() {
    return (
        <div className='about'>

            <h1>About<hr className='headerHR'/></h1>

            <div className='aboutText'>
                <h2 className='heading'>About the show:</h2>
                
                <p>
                This website is intended to provide access to archives and playlists for Live from the Dungeon,
                a radio program aired Fridays from 10PM - 11PM on <a className="extLink" href="https://wcbn.org/" target='_blank' rel='noreferrer'>WCBN </a> 88.3 FM in Ann Arbor, MI. <br/>
                <br/>

                Hosted by DJ Double Two, Live from the Dungeon is a Hip-Hop show, with a focus on older styles like
                Boom-Bap and Jazz-Rap with new styles including Backpack (whatever that means to you). Tracks played for the show are from my personal vinyl/CD collection as well as
                the WCBN music library. Live from the Dungeon's show time falls within the FCC safe harbor, allowing me
                to play explicit Hip-Hop for your listening pleasure.<br/>

                <br/>
                Live from the Dungeon's first season on air was the summer of 2022. To date, the only seasons off air
                since its premiere were summer 2023 and summer 2024. As host, I attempt to plan themed shows for every weekly broadcast,
                be sure to check the <Link className='hereLink' to='/'>Last Episode</Link> page for each week's theme.<br/>

                </p>

                <h2>
                Who is DJ Double Two:
                </h2>
                
                <p>
                DJ Double Two is an undergraduate student at the University of Michigan's college of engineering, a huge
                Hip-Hop nerd, and a music collector. I stopped exactly counting how many records/CDs/cassettes I owned and
                left that burden up to Discogs. If you ever, for some odd reason, want to see my entire collection, my <a className="extLink" href="https://www.discogs.com/user/DJ.DoubleTwo/collection?header=1" target='_blank' rel='noreferrer'>Discogs </a>
                profile is linked at the bottom of page or on the side bar. The truth of the origin of this show is that I needed an excuse
                to play all of this physical music that I own. <br/>
                
                <br/>
                On air, I try to stay true to my love for classic Hip-Hop and provide hours of my favorite music ever. 
                </p>

                <p className='tag'>~DJ Double Two</p>
                
            </div>
            
            <hr/>

        </div>
    );
};

export default About;