// SearchBar.js 

import React from 'react';
import search from '../../assets/search.png';
import './SearchBar.css';

function SearchBar() {
    const searchArchive = (event) => {
        event.preventDefault();
        let searchDate = document.getElementById("searchInput").value.trim();
        let archives = document.querySelector('.allArchives');

        for (let i = 0; i < archives.children.length; i++) {
            if (archives.children[i].textContent.toLowerCase().includes(searchDate.toLowerCase())) {
                archives.children[i].style.display = "block";
            }
            else {
                archives.children[i].style.display = "none"; 
            }
        }
    };

    return (
        <div className='searchBar'>
            <form id='archiveSearch' onSubmit={searchArchive}>
                <div className='inputContainer'>
                    <input type="text" id="searchInput" placeholder="Search By Date Or Title"/>
                    <div className='searchContainer'>
                        <img src={search} alt="Search Icon" className='searchIcon' width='25px' height='25px' onClick={searchArchive} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;