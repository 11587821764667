// Socials.js

import React from "react";
import './Socials.css';
import spinitronLogo from '../../assets/spinitron.png';
import discogsLogo from '../../assets/discogs.png';
import instagramLogo from '../../assets/instagram.png';
import WCBNLogo from '../../assets/transparent-WCBN.png';

function Socials() {
    return (
        <div className='socialsMobile'>
            <a href="https://spinitron.com/WCBN/dj/167326/DJ-Double-Two" target='_blank' rel='noreferrer'><img src={spinitronLogo} alt="Spinitron logo" className='footerImg'></img></a>
            <a href="https://www.instagram.com/dj.doubletwo/" target='_blank' rel='noreferrer'><img src={instagramLogo} alt="Instagram logo" className='footerImg'></img></a>
            <a href="https://www.discogs.com/user/DJ.DoubleTwo/collection?header=1" target='_blank' rel='noreferrer'><img src={discogsLogo} alt="Discogs logo" className='footerImg'></img></a>
            <a href="https://wcbn.org/" target='_blank' rel='noreferrer'><img src={WCBNLogo} alt="WCBN Logo" className='footerImg'></img></a>
        </div>
    );
};

export default Socials;