// TopBar.js

import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './TopBar.css';
import hzLogo from '../../assets/LFTD_Logo_Transparent_hz.png';
import lastEpisode from '../../assets/lastEpisode.png';
import archive from '../../assets/archive.png';
import playlist from '../../assets/playlist.png';
import about from '../../assets/about.png';
import signUp from '../../assets/signUp.png';
import { MdOutlineMenu, MdClose } from 'react-icons/md';

function TopBar({handleSignUp}) {
    const Hamburger = <MdOutlineMenu className='HamburgerMenu' size='30px' onClick={() => sethbClick(!hbClick)} />
    const Close = <MdClose className='HamburgerMenu' size='30px' onClick={() => sethbClick(!hbClick)} />
    const [hbClick, sethbClick] = useState(false);

    const handleMenuItem = () => {
        sethbClick(false);
    }

    return (
        <div className='topBar'>
            
            <div className='logoHz'>
                <Link to='/' onClick={handleMenuItem}><img src={ hzLogo } height='90vh' alt='LFTD Logo'/></Link>
                {/* <div><Link to='/' onClick={handleMenuItem}>Live from the Dungeon</Link></div> */}
            </div>

            { hbClick ? Close : Hamburger }

            { hbClick && (
                <div className='topBarNav'>
                    <li className='mobileLi'><NavLink to="/" activeClassName="active-nav" onClick={handleMenuItem}><img src={lastEpisode} alt='Recent' className='navImg'/> Most Recent</NavLink></li>
                    <li className='mobileLi'><NavLink to="/Archive" activeClassName="active-nav" onClick={handleMenuItem}><img src={archive} alt='Archive' className='navImg'/> Archive</NavLink></li>
                    <li className='mobileLi'><NavLink to="/Playlists" activeClassName="active-nav" onClick={handleMenuItem}><img src={playlist} alt='Playlist' className='navImg'/> Playlists</NavLink></li>
                    <li className='mobileLi'><NavLink to="/About" activeClassName="active-nav" onClick={handleMenuItem}><img src={about} alt='About' className='navImg'/> About</NavLink></li>
                    <li className='mobileLi' onClick={() => {handleSignUp(); handleMenuItem();}}><div className='signUpLi'><img src={signUp} alt='signUp' className='navImg'/> Sign Up</div></li>
                </div>
            ) }
        </div>
    );
};

export default TopBar;