// LastEpisode.js

import React from 'react';
import './LastEpisode.css';
import download from '../../assets/download.png';
import external from '../../assets/external.png';
const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function MostRecent() {

    /* const audioSrc = "http://35.7.34.15:5150/(0) Last Episode/LastEpisode.mp3" */
    const audioSrc = "https://djdoubletwo.duckdns.org:5150/(0) Last Episode/LastEpisode.mp3"
    const driveLink = "https://drive.google.com/uc?export=download&id=1Ceg-h6pGNCWgsWGq37icZMBH7LBdp4CA"
    const epName = "9/13/24 It's Been A Long Time"
    const spinLink = "https://spinitron.com/WCBN/pl/19511838/Live-from-the-Dungeon"
    const lastDescription = "DJ Double Two welcomes you back to Live from the Dungeon with an hour of classic Hip-Hop. Enjoy."

    return (
        <div className='lastEpisode'>
            
            <h1>Last Episode<hr className='headerHR'/></h1>
            
            <div className='recentContent'>

                <h2 className='lastLink'><a href={driveLink}>{epName}: <img src={ download } className='downloadIcon' alt='Download Icon' width="25px"/></a></h2>
                
                <div className='lastDescription'>{lastDescription}</div>
               
                <NowPlaying name={epName} linkSrc={audioSrc} />

                <hr/>
                
                <div className='tracks'>
                    <h2 className='lastLink'><a href={spinLink} target='_blank' rel='noreferrer'>Playlist:  <img src={ external } className='externalIcon' alt='External Icon' width="20px"/></a></h2>
                    <div className='playlist'>
                        <li className='MRLi'>1. C.L. Smooth & Pete Rock / They Reminisce Over You (T.R.O.Y.) / Mecca and the Soul Brother / Elektra 0591 / 1992</li>
                        <li className='MRLi'>2. Eric B. & Rakim / Don't Sweat the Technique / Don't Sweat the Technique / Geffen* / 1992</li>
                        <li className='MRLi'>3. Lords of the Underground / Chief Rocka / Here Come the Lords / Pendulum Records / 1993</li>
                        <li className='MRLi'>4. A Tribe Called Quest / Check the Rhime / The Low End Theory / Jive / 1991</li>
                        <li className='MRLi'>5. Da Bush Babees / We Run Things (It's Like Dat) / Ambushed / Reprise / 1994</li>
                        <li className='MRLi'>6. Black Moon / Who Got Da Props / Enta Da Stage / Nervous Records / 1993</li>
                        <li className='MRLi'>7. Gang Starr / Dwyck / Hard to Earn / Virgin Records / 1994</li>
                        <li className='MRLi'>8. The Notorious B.I.G. / Party and Bulls**t / Notorious (Music from and Inspired By the Original Motion Picture) [Deluxe Version] / Bad Boy Records / 2009</li>
                        <li className='MRLi'>9. Xzibit / Paparazzi / At The Speed Of Life / LOUD Records / 1996</li>
                        <li className='MRLi'>10. Camp Lo / Coolie High / Uptown Saturday Night / Arista / 1997</li>
                        <li className='MRLi'>11. Slum Village / Players / Fan-Tas-Tic, Vol. 2 / Ne’Astra Music / 2009</li>
                        <li className='MRLi'>12. Redman / Tonight's Da Night / Whut? Thee Album / RAL (Rush Associated Label) / 1992</li>
                        <li className='MRLi'>13. Pete Rock & InI / Think Twice / Center of Attention / VinDig / 2017</li>
                        <li className='MRLi'>14. Souls of Mischief / 93 'Til Infinity / 93 'til Infinity / Jive / 1993</li>
                    </div>
                </div>
            </div>

            <hr/>

        </div>
    );
};

export default MostRecent;