// Playlists.js

import React from 'react';
import './Playlists.css';

function Playlists() {
    return (
        <div className='playlists'>

            <h1>Playlists<hr className='headerHR'/></h1>

            <iframe src='https://spinitron.com/WCBN/show/249781/Live-from-the-Dungeon' className='spinitronPlaylist' title='spinitronPlaylist' /* width="100%" */></iframe>

        </div>
    );
};

export default Playlists;