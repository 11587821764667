// Archive.js

import React, { useState, useTransition } from 'react';
import './Archive.css';
import { Link } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';
import playIcon from '../../assets/playIcon.png';

const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function Archive() {
    const [, startTransition] = useTransition();
    const [showComponent, setShowComponent] = useState(false);
    const [archTit, setArchTit] = useState("");
    const [linkSrc, setLink] = useState("");
    /* const host = "http://35.7.34.15:5150" */
    const host = "https://djdoubletwo.duckdns.org:5150"

    const handleClick = (tit, link) => {
        setShowComponent(false);
        startTransition(() => {
            if (tit === archTit && link === linkSrc) {
                setShowComponent(false);
                setArchTit("");
                setLink("");
            }
            else {
                setShowComponent(true);
                setArchTit(tit);
                setLink(link);
            }
        });
    };

    /* const handleClickClose = () => {
        setShowComponent(false);
    } */

    return (
        <div className='archive'>
            <h1>Archive<hr className='headerHR'/></h1>
            
            <p>
            See below for audio archives of past broadcasts of Live from the Dungeon. Track listings are available <Link className='hereLink' to='/Playlists'>here</Link>.<br/>
            </p>

            <hr/>

            <SearchBar />

            <hr/>
            
            <div className='allArchives'>

                <div className='indvArchive'><h3 onClick={() => handleClick("9/13/24 It's Been A Long Time", host + "/(6.01)%209-13-24%20It's%20Been%20A%20Long%20Time/wcbn-2024-09-13-2200-EDT.mp3")}>9/13/24 It's Been A Long Time<img src={ playIcon } className='playIcon1' alt='Play Icon' width='30px'/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("5/10/24 Live from the Dungeon", host + "/(5.15)%205-10-24%20Live%20from%20the%20Dungeon/wcbn-2024-05-10-2200-EDT.mp3")}>5/10/24 Live from the Dungeon<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("5/03/24 Diggin' In the Crates", host + "/(5.14)%205-03-24%20Diggin'%20In%20The%20Crates/wcbn-2024-05-03-2200-EDT.mp3")}>5/03/24 Diggin' In The Crates<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/19/24 You Know We Had To Do A Remix, Right? Vol. 2", host + "/(5.13)%204-19-24%20You%20Know%20We%20Had%20To%20Do%20A%20Remix,%20Right%3f%20Vol.%202/wcbn-2024-04-19-2200-EDT.mp3")}>4/19/24 You Know We Had To Do A Remix, Right? Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/12/24 Queens Is In The House", host + "/(5.12)%204-12-24%20Queens%20Is%20In%20The%20House/wcbn-2024-04-12-2200-EDT.mp3")}>4/12/24 Queens Is In The House<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/05/24 The Native Tongues Has Officially Been Reinstated", host + "/(5.11)%204-05-24%20The%20Native%20Tongues%20Has%20Officially%20Been%20Reinstated/wcbn-2024-04-05-2200-EDT.mp3")}>4/05/24 The Native Tongues Has Officially Been Reinstated<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/29/24 Remember Rawkus?", host + "/(5.10)%203-29-24%20Remember%20Rawkus%3f/wcbn-2024-03-29-2200-EDT.mp3")}>3/29/24 Remember Rawkus?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/22/24 Beats By Su-Primo, Vol. 2", host + "/(5.09)%203-22-24%20Beats%20By%20Su-Primo,%20Vol.%202/wcbn-2024-03-22-2200-EDT.mp3")}>3/22/24 Beats By Su-Primo, Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/15/24 Soul Brother #1 Soul Brother", host + "/(5.08)%203-15-24%20Soul%20Brother%20%231%20Soul%20Brother/wcbn-2024-03-15-2200-EDT.mp3")}>3/15/24 Soul Brother #1 Soul Brother<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/08/24 Mad Props", host + "/(5.07)%203-08-24%20Mad%20Props/wcbn-2024-03-08-2200-EST.mp3")}>3/08/24 Mad Props<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/23/24 You Know We Had To Do A Remix, Right?", host + "/(5.06)%202-23-24%20You%20Know%20We%20Had%20To%20Do%20A%20Remix,%20Right%3f/wcbn-2024-02-23-2200-EST.mp3")}>2/23/24 You Know We Had To Do A Remix, Right?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/16/24 What's Love... ", host + "/(5.05)%202-16-24%20What's%20Love...%20%3f/wcbn-2024-02-16-2200-EST.mp3")}>2/16/24 What's Love... ?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/09/24 Dilla Day 2024", host + "/(5.04)%202-09-24%20Dilla%20Day%202024/wcbn-2024-02-09-2200-EST.mp3")}>2/09/24 Dilla Day 2024<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/26/24 It Takes Two, Vol. 2", host + "/(5.03)%201-26-24%20It%20Takes%20Two,%20Vol.%202/wcbn-2024-01-26-2200-EST.mp3")}>1/26/24 It Takes Two, Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/19/24 Time to Catch Wreck", host + "/(5.02)%201-19-24%20Time%20to%20Catch%20Wreck/wcbn-2024-01-19-2200-EST.mp3")}>1/19/24 Time to Catch Wreck<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/12/24 Murder Mitten Music", host + "/(5.01)%201-12-24%20Murder%20Mitten%20Music/wcbn-2024-01-12-2200-EST.mp3")}>1/12/24 Murder Mitten Music<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/05/24 Another Replay?", host + "/(4.15)%201-05-24%20Another%20Replay%3f/wcbn-2024-01-05-2200-EST.mp3")}>1/05/24 Another Replay?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/29/23 That '23 Ish Is Dead", host + "/(4.14)%2012-29-23%20That%20'23%20Ish%20Is%20Dead/wcbn-2023-12-29-2200-EST.mp3")}>12/29/23 That '23 Ish Is Dead<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/15/23 The Year Was 1996...", host + "/(4.13)%2012-15-23%20The%20Year%20Was%201996.../wcbn-2023-12-15-2200-EST.mp3")}>12/15/23 The Year Was 1996...<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/08/23 Big Ups to Brooklyn", host + "/(4.12)%2012-08-23%20Big%20Ups%20to%20Brooklyn/wcbn-2023-12-08-2200-EST.mp3")}>12/08/23 Big Ups to Brooklyn<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/01/23 Chillin' Out, Maxin', Relaxin' All Cool, Vol. 2", host + "/(4.11)%2012-01-23%20Chillin'%20Out,%20Maxin',%20Relaxin'%20All%20Cool,%20Vol.%202/wcbn-2023-12-01-2200-EST.mp3")}>12/01/23 Chillin' Out, Maxin', Relaxin' All Cool, Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/24/23 Hip-Hop Anthems", host + "/(4.10)%2011-24-23%20Hip-Hop%20Anthems/wcbn-2023-11-24-2200-EST.mp3")}>11/24/23 Hip-Hop Anthems<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/17/23 Welcome to Detroit", host + "/(4.09)%2011-17-23%20Welcome%20to%20Detroit/wcbn-2023-11-17-2200-EST.mp3")}>11/17/23 Welcome to Detroit<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/10/23 Who Can Quest Like the Wu Does? Vol. 2", host + "/(4.08)%2011-10-23%20Who%20Can%20Quest%20Like%20the%20Wu%20Does%3f%20Vol.%202/wcbn-2023-11-10-2200-EST.mp3")}>11/10/23 Who Can Quest Like the Wu Does? Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/27/23 Hip-Hop Halloween 2023", host + "/(4.06)%2010-27-23%20Hip-Hop%20Halloween%202023/wcbn-2023-10-27-2200-EDT.mp3")}>10/27/23 Hip-Hop Halloween 2023<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/20/23 Forming the Free", host + "/(4.05)%2010-20-23%20Forming%20the%20Free/wcbn-2023-10-20-2200-EDT.mp3")}>10/20/23 Forming the Free<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/13/23 Chillin' Out, Maxin', Relaxin' All Cool", host + "/(4.04)%2010-13-23%20Chillin'%20Out,%20Maxin',%20Relaxin'%20All%20Cool/wcbn-2023-10-13-2200-EDT.mp3")}>10/13/23 Chillin' Out, Maxin', Relaxin' All Cool<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/06/23 I Got a Story to Tell", host + "/(4.03)%2010-06-23%20I%20Got%20a%20Story%20to%20Tell/wcbn-2023-10-06-2200-EDT.mp3")}>10/06/23 I Got a Story to Tell<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/29/23 Soul Connections", host + "/(4.02)%209-29-23%20Soul%20Connections/wcbn-2023-09-29-2200-EDT.mp3")}>9/29/23 Soul Connections<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/22/23 And Now For My Next Number", host + "/(4.01)%209-22-23%20And%20Now%20For%20My%20Next%20Number/wcbn-2023-09-22-2200-EDT.mp3")}>9/22/23 And Now For My Next Number<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/28/23 A Healthy Blend", host + "/(3.17)%204-28-23%20A%20Healthy%20Blend/wcbn-2023-04-28-2200-EDT.mp3")}>4/28/23 A Healthy Blend<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/21/23 Rent is Due", host + "/(3.16)%204-21-23%20Rent%20Is%20Due/wcbn-2023-04-21-2200-EDT.mp3")}>4/21/23 Rent is Due<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/14/23 As Diamond", host + "/(3.15)%204-14-23%20As%20Diamond/wcbn-2023-04-14-2200-EDT.mp3")}>4/14/23 As Diamond<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("4/7/23 Wu-Tang Sword", host + "/(3.14)%204-7-23%20Shaolin%20and%20the%20Wu-Tang/Wu-Tang%20Sword.mp3")}>4/7/23 Wu-Tang Sword<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/31/23 Bobby Boy", host + "/(3.13)%203-31-23%20Bobby%20Boy/wcbn-2023-03-31-2200-EDT.mp3")}>3/31/23 Bobby Boy<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/24/23 We Got the Jazz", host + "/(3.12)%203-24-23%20We%20Got%20the%20Jazz/LFTD_3_24_23.mp3")}>3/24/23 We Got the Jazz<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/17/23 What's In A Name: Soulquarian", host + "/(3.11)%203-17-23%20What's%20In%20A%20Name%20-%20Soulquarian/wcbn-2023-03-17-2200-EDT.mp3")}>3/17/23 What's In A Name: Soulquarian<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/10/23 A Tour of the Underground", host + "/(3.10)%203-10-23%20A%20Tour%20of%20the%20Underground/wcbn-2023-03-10-2200-EST.mp3")}>3/10/23 A Tour of the Underground<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("3/3/23 The Magic Number", host + "/(3.09)%203-3-23%20The%20Magic%20Number/wcbn-2023-03-03-2200-EST.mp3")}>3/3/23 The Magic Number<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/24/23 Who's Chillin' Around the Land, Vol. 2", host + "/(3.08)%202-24-23%20Who's%20Chillin'%20Around%20the%20Land,%20Vol.%202/wcbn-2023-02-24-2200-EST.mp3")}>2/24/23 Who's Chillin' Around the Land, Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/17/23 Let's Talk About Love", host + "/(3.07)%202-17-23%20Let's%20Talk%20About%20Love%20-%20Sub/wcbn-2023-02-17-2200-EST.mp3")}>2/17/23 Let's Talk About Love<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/10/23 Dilla Day", host + "/(3.06)%202-10-23%20Dilla%20Day/wcbn-2023-02-10-2200-EST.mp3")}>2/10/23 Dilla Day<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("2/3/23 Soulful Stylings of Hip-Hop", host + "/(3.05)%202-3-23%20Soulful%20Stylings%20of%20Hip-Hop/wcbn-2023-02-03-2200-EST.mp3")}>2/3/23 Soulful Stylings of Hip-Hop<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/27/23 Who's Chillin' Around the Land?", host + "/(3.04)%201-27-23%20Who's%20Chillin'%20Around%20the%20Land%3f/wcbn-2023-01-27-2200-EST.mp3")}>1/27/23 Who's Chillin' Around the Land?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/20/23 Welcome, Vol. 2", host + "/(3.03)%201-20-23%20Welcome,%20Vol.%202%20+%20%5bSUB%5d%20Calling%20Planet%20Earth/Welcome,%20Vol.%202.mp3")}>1/20/23 Welcome, Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/13/23 DOOMSDAY Vol. 2", host + "/(3.02)%201-13-23%20DOOMSDAY%20Vol.%202/wcbn-2023-01-13-2200-EST.mp3")}>1/13/23 DOOMSDAY Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("1/6/23 P-Funkin' the Fake", host + "/(3.01)%201-6-23%20P-Funkin'%20the%20Fake/wcbn-2023-01-06-2200-EST.mp3")}>1/6/23 P-Funkin' the Fake<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/30/22 Replaying the Replay", host + "/(2.17)%2012-30-22%20Replay%20the%20Replay/wcbn-2022-12-30-2200-EST.mp3")}>12/30/22 Replaying the Replay<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/23/22 I Let My Freeform Form Free Vol. 3", host + "/(2.16)%2012-23-22%20I%20Let%20My%20Freeform%20Form%20Free,%20Vol.%203/wcbn-2022-12-23-2200-EST.mp3")}>12/23/22 I Let My Freeform Form Free Vol. 3<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/16/22 I Let My Freeform Form Free Vol. 2", host + "/(2.15)%2012-16-22%20I%20Let%20My%20Freeform%20Form%20Free%20Vol.%202/wcbn-2022-12-16-2200-EST.mp3")}>12/16/22 I Let My Freeform Form Free Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/9/22 It Takes Two", host + "/(2.14)%2012-9-22%20It%20Takes%20Two/wcbn-2022-12-09-2200-EST.mp3")}>12/9/22 It Takes Two<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("12/2/22 Going Back to Cali", host + "/(2.13)%2012-2-22%20Going%20Back%20to%20Cali/wcbn-2022-12-02-2200-EST.mp3")}>12/2/22 Going Back to Cali<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/25/22 Giving Thanks: Family", host + "/(2.12)%2011-25-22%20Giving%20Thanks%20-%20Family/wcbn-2022-11-25-2200-EST.mp3")}>11/25/22 Giving Thanks: Family<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/18/22 I Let My Freeform Form Free", host + "/(2.11)%2011-18-22%20I%20Let%20My%20Freeform%20Form%20Free/wcbn-2022-11-18-2200-EST.mp3")}>11/18/22 I Let My Freeform Form Free<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/11/22 Who can Quest like the Wu does?", host + "/(2.10)%2011-11-22%20Who%20can%20Quest%20like%20the%20Wu%20does%3f/wcbn-2022-11-11-2200-EST.mp3")}>11/11/22 Who can Quest like the Wu does?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("11/4/22 Beats By Su-Primo", host + "/(2.09)%2011-4-22%20Beats%20By%20Su-Primo/wcbn-2022-11-04-2200-EDT.mp3")}>11/4/22 Beats By Su-Primo<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/28/22 Hip-Hop Halloween", host + "/(2.08)%2010-28-22%20Hip-Hop%20Halloween/wcbn-2022-10-28-2200-EDT.mp3")}>10/28/22 Hip-Hop Halloween<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/21/22 See You Space Cowboy...", host + "/(2.07)%2010-21-22%20See%20You%20Space%20Cowboy.../wcbn-2022-10-21-2200-EDT.mp3")}>10/21/22 See You Space Cowboy...<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/14/22 Southern Playalistic Muzik", host + "/(2.06)%2010-14-22%20Southern%20Playalistic%20Muzik/wcbn-2022-10-14-2200-EDT.mp3")}>10/14/22 Southern Playalistic Muzik<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("10/7/22 Welcome", host + "/(2.05)%2010-7-22%20Welcome/wcbn-2022-10-07-2200-EDT.mp3")}>10/7/22 Welcome<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/30/22 The Art of Story Telling and Picture Painting Vol. 2", host + "/(2.04)%209-30-22%20The%20Art%20of%20Story%20Telling%20and%20Picture%20Painting%20Vol.%202/wcbn-2022-09-30-2200-EDT.mp3")}>9/30/22 The Art of Story Telling and Picture Painting Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/23/22 Songs and Samples", host + "/(2.03)%209-23-22%20Songs%20and%20Samples/wcbn-2022-09-23-2200-EDT.mp3")}>9/23/22 Songs and Samples<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/16/22 Who can Quest like 'a Tribe does?", host + "/(2.02)%209-16-22%20Who%20can%20Quest%20like%20'a%20Tribe%20does%3f/wcbn-2022-09-16-2200-EDT.mp3")}>9/16/22 Who can Quest like 'a Tribe does?<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("9/9/22 An Ode to the Classics Vol. 2", host + "/(2.01)%209-9-22%20Hip-Hop%20Classics%202/wcbn-2022-09-09-2200-EDT.mp3")}>9/9/22 An Ode to the Classics Vol. 2<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("8/26/22 DOOMSDAY", host + "/(1.06)%208-26-22%20DOOMSDAY/wcbn-2022-08-26-0200-EDT.mp3")}>8/26/22 DOOMSDAY<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("8/19/22 Name Taking", host + "/(1.05)%208-19-22%20Name%20Taking/wcbn-2022-08-19-0200-EDT.mp3")}>8/19/22 Name Taking<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("8/12/22 An Ode to Hip-Hop", host + "/(1.04)%208-12-22%20An%20Ode%20to%20Hip-Hop/wcbn-2022-08-12-0200-EDT.mp3")}>8/12/22 An Ode to Hip-Hop<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("8/5/22 Detroit to Chicago", host + "/(1.03)%208-5-22%20Sub%20+%20Detroit%20to%20Chicago/8_5_2022_Live_From_the_Dungeon.mp3")}>8/5/22 Detroit to Chicago<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("7/29/22 The Art of Story Telling", host + "/(1.02)%207-29-22%20The%20Art%20of%20Storytelling/wcbn-2022-07-29-0200-EDT.mp3")}>7/29/22 The Art of Story Telling<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
                <div className='indvArchive'><h3 onClick={() => handleClick("7/22/22 Soulful Sounds", host + "/(1.01)%207-22-22%20Soulful%20Sounds/Soulful_Sounds_7_22_22.mp3")}>7/22/22 Soulful Sounds<img src={ playIcon } className='playIcon1' alt='Play Icon' width="30px"/></h3></div>
            </div>

            { showComponent && <NowPlaying name={archTit} linkSrc={linkSrc} /> }

            <hr/>

        </div>
    );
};

export default Archive;