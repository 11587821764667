// App.js

import React, { useState } from 'react'
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import SideBar from './components/SideBar/SideBar';
import TopBar from './components/TopBar/TopBar';
import LastEpisode from './components/LastEpisode/LastEpisode';
import Playlists from './components/Playlists/Playlists';
import Archive from './components/Archive/Archive';
import About from './components/About/About';
import Socials from './components/Socials/Socials';
import SignUp from './components/SignUp/SignUp';
import './App.css';

function App() {
    const isDesktop = useMediaQuery({ minWidth: 769 });
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [showSignUp, setShowSignUp] = useState(false);
    const handleSignUp = () => {
        setShowSignUp(!showSignUp);
    };

    return (
        <Router>

            <div className='App'>

                {/* <SignUp /> */}

                <div className='nav'>
                    { isDesktop && <SideBar handleSignUp={handleSignUp} /> }
                    { isMobile && <TopBar handleSignUp={handleSignUp} /> }
                </div>
                
                <div className='mainContent'>

                    <div className='content'>

                        <Routes>
                            <Route exact path="/" element={<LastEpisode />}></Route>
                            <Route path="/Archive" element={<Archive />}></Route>
                            <Route path="/Playlists" element={<Playlists />}></Route>
                            <Route path="/About" element={<About />}></Route>
                        </Routes>

                    </div>
                
                </div>

            </div>

            { isMobile && <Socials /> }
            
            { showSignUp && <SignUp show={showSignUp} handleSignUp={handleSignUp} /> }

        </Router>
    );
};

export default App;
