// SignUp.js

import React from 'react';
import { MdClose } from 'react-icons/md';
import './SignUp.css';

function SignUp({show, handleSignUp}) {
    const Close = <MdClose className='signUpClose' size='30px' onClick={handleSignUp}/>

    if (!show) {
        return null;
    }

    const handleInnerDivClick = (event) => {
        event.stopPropagation();
    }

    const formSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);

        try {
            const response = await fetch('https://formspree.io/f/mjvnakyb', 
                                        {method: 'POST',
                                         body: new URLSearchParams(formData), 
                                         headers: {'Accept': 'application/json'}});
            if (response.ok) {
                alert('Signup received successfully. Thank you!');
                handleSignUp(false);
            }
            else {
                alert('Signup not received. Please try again.');
            }
        }
        catch (error) {
            alert('Error submitting signup:', error);
        }
    };

    return (
        <div className='signUp' onClick={handleSignUp}>

            <div className='signUpText' onClick={handleInnerDivClick}>

                <h3 className='question'>Want to receive weekly Live from the Dungeon recordings via email?</h3>

                <form id="myForm" onSubmit={formSubmit}>
                    <input type="email" id="email" name="email" required title="Enter your email address" placeholder="Enter Your Email"></input><br className='mobileBr'/>
                    <button className='subButton' type="submit">Submit</button>
                </form>

                <div className='closeIcon'>
                    { Close }
                </div>
    
            </div>

        </div>
    );
};

export default SignUp;
