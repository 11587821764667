// SideBar.js

import React/* , { useState } */ from 'react';
import './SideBar.css';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../assets/LFTD_Logo_Transparent.png';
import spinitronLogo from '../../assets/spinitron.png';
import discogsLogo from '../../assets/discogs.png';
import instagramLogo from '../../assets/instagram.png';
import WCBNLogo from '../../assets/transparent-WCBN.png';
import lastEpisode from '../../assets/lastEpisode.png';
import archive from '../../assets/archive.png';
import playlist from '../../assets/playlist.png';
import about from '../../assets/about.png';
import signUp from '../../assets/signUp.png';

function SideBar({handleSignUp}) {
    return (
        <div className='sideBar'>

            <div className='logo'>
                <Link to='/'><img src={logo} width='200px' height='200px' alt="LFTD Logo"></img></Link>
            </div>

            <div className='navDiv'>
                <ul className='nav'>
                    <div className='sideBarLiDiv'><NavLink to="/" activeClassName="active-nav"><li className='sideBarLi'><img src={lastEpisode} alt='Recent' className='navImg'/> Last Episode</li></NavLink></div>
                    <div className='sideBarLiDiv'><NavLink to="/Archive" activeClassName="active-nav"><li className='sideBarLi'><img src={archive} alt='Archive' className='navImg'/> Archive</li></NavLink></div>
                    <div className='sideBarLiDiv'><NavLink to="/Playlists" activeClassName="active-nav"><li className='sideBarLi'><img src={playlist} alt='Playlist' className='navImg'/> Playlists</li></NavLink></div>
                    <div className='sideBarLiDiv'><NavLink to="/About" activeClassName="active-nav"><li className='sideBarLi'><img src={about} alt='About' className='navImg'/> About</li></NavLink></div>
                    <div className='signUpLi'><li className='sideBarLi' onClick={handleSignUp}><img src={signUp} alt='About' className='navImg'/> Sign Up</li></div>
                </ul>
            </div>

            <div className='footer'>
                
                <hr/>

                <div className='socials'>
                    <a href="https://spinitron.com/WCBN/dj/167326/DJ-Double-Two" target='_blank' rel='noreferrer'><img src={spinitronLogo} alt="Spinitron logo" className='footerImg'></img></a>
                    <a href="https://www.instagram.com/dj.doubletwo/" target='_blank' rel='noreferrer'><img src={instagramLogo} alt="Instagram logo" className='footerImg'></img></a>
                    <a href="https://www.discogs.com/user/DoubleTwoRecords" target='_blank' rel='noreferrer'><img src={discogsLogo} alt="Discogs logo" className='footerImg'></img></a>
                    <a href="https://wcbn.org/" target='_blank' rel='noreferrer'><img src={WCBNLogo} alt="WCBN Logo" className='footerImg'></img></a>
                </div>

                {/* <SignUp /> */}

            </div>

        </div>
    );
};

export default SideBar;